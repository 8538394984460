import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../common/CustomLink';
import { StaticImage } from 'gatsby-plugin-image';

interface FollowUsProps {
  variant?: 'default' | 'drawer';
}

const FollowUs: React.FC<FollowUsProps> = ({ variant = 'default' }) => {
  const { t } = useTranslation();

  const containerStyles = variant === 'default' 
    ? "mx-auto flex flex-col items-center justify-center gap-6 lg:flex-row"
    : "flex flex-col items-center justify-center gap-4 px-4";

  const socialContainerStyles = variant === 'default'
    ? "flex items-center gap-11 rounded-[1rem] border border-[#E8E8E8] px-6 py-3"
    : "flex items-center gap-8 rounded-[1rem] border border-[#E8E8E8] px-4 py-2";

  const titleStyles = variant === 'default'
    ? "font-poppins text-[1.4rem] font-semibold leading-[2.1rem] text-[#636363]"
    : "font-poppins text-[1.2rem] font-semibold leading-[1.8rem] text-[#636363]";

  return (
    <div className={containerStyles}>
      <p className={titleStyles}>
        {t('testimonials.follow', 'Follow us')}
      </p>
      <div className={socialContainerStyles}>
        <div className="h-[2rem] w-[1rem]">
          <CustomLink
            url={'https://web.facebook.com/suwadiviyalk/?_rdc=1&_rdr'}
            target="_blank"
            rel="noopener noreferrer"
            ariaLabel="Follow us on Facebook"
            label={
              <StaticImage
                src={'../../../../images/socialMedia/facebook.png'}
                alt={'Facebook'}
                className="object-cover object-center"
                placeholder="blurred"
              />
            }
          />
        </div>
        <div className="h-[1.7rem] w-[1.7rem]">
          <CustomLink
            url={'https://www.instagram.com/suwa_diviya/'}
            target="_blank"
            rel="noopener noreferrer"
            ariaLabel="Follow us on Instagram"
            label={
              <StaticImage
                src={'../../../../images/socialMedia/instagram.png'}
                alt={'Instagram'}
                className="object-cover object-center"
                placeholder="blurred"
              />
            }
          />
        </div>
        <div className="h-[1.3rem] w-[1.9rem]">
          <CustomLink
            url={'https://www.youtube.com/@SuwaDiviya'}
            target="_blank"
            rel="noopener noreferrer"
            ariaLabel="Subscribe to our YouTube channel"
            label={
              <StaticImage
                src={'../../../../images/socialMedia/youtube.png'}
                alt={'YouTube'}
                className="object-cover object-center"
                placeholder="blurred"
              />
            }
          />
        </div>
        <div className="h-[1.7rem] w-[1.7rem]">
          <CustomLink
            url={'https://www.linkedin.com/company/suwa-diviya/posts/?feedView=all'}
            target="_blank"
            rel="noopener noreferrer"
            ariaLabel="Follow us on LinkedIn"
            label={
              <StaticImage
                src={'../../../../images/socialMedia/linkedin.png'}
                alt={'LinkedIn'}
                className="object-cover object-center"
                placeholder="blurred"
              />
            }
          />
        </div>
        <div className="h-[1.7rem] w-[1.7rem]">
          <CustomLink
            url={'https://www.tiktok.com/@suwa.diviya/'}
            target="_blank"
            rel="noopener noreferrer"
            ariaLabel="Follow us on TikTok"
            label={
              <StaticImage
                src={'../../../../images/socialMedia/tiktok.png'}
                alt={'TikTok'}
                className="object-cover object-center"
                placeholder="blurred"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FollowUs;
