import React from 'react';
import { Button } from '@mui/material';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button' | undefined;
  width?: string;
  height?: string;
  borderRadius?: 'low' | 'high' | string;
  borderWidth?: string;
  borderColor?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isValid?: boolean;
  disabled?: boolean;
  color?: string;
  fontWeight?: string;
  padding?: string | number;
  variant?: 'contained' | 'outlined' | 'text';
  ariaLabel?: string;
};

const CustomButton = ({
  children,
  type = 'button',
  width = 'auto',
  height = 'auto',
  borderRadius = 'low',
  borderWidth = '2px',
  borderColor = '#FAA61A',
  backgroundColor = 'transparent',
  onClick,
  isValid = true,
  disabled = false,
  color = 'black',
  fontWeight = '500',
  padding = 0,
  variant = 'contained',
  ariaLabel,
}: Props) => {
  const getBorderRadius = (radius: 'low' | 'high' | string): string => {
    switch (radius) {
      case 'low':
        return '1.1rem';
      case 'high':
        return '2.2rem';
      default:
        if (/^[0-9]+(%|px|em|rem|vh|vw)$/.test(radius)) {
          return radius;
        }
        if (/^[0-9]+$/.test(radius)) {
          return `${radius}px`;
        }
        return '1.1rem';
    }
  };

  // Extract text content from children for aria-label if not provided
  const getAriaLabel = () => {
    if (ariaLabel) return ariaLabel;
    if (typeof children === 'string') return children;
    if (React.isValidElement(children) && typeof children.props.children === 'string') 
      return children.props.children;
    return undefined;
  };

  return (
    <Button
      type={type}
      variant={variant}
      aria-label={getAriaLabel()}
      sx={{
        width,
        height,
        padding: padding ? padding : '0',
        borderRadius: getBorderRadius(borderRadius),
        border: `${borderWidth} solid ${borderColor}`,
        backgroundColor: backgroundColor,
        cursor: disabled ? 'default' : 'pointer',
        transition: 'all 0.2s ease',
        opacity: !isValid || disabled ? '0.6' : '1',
        fontWeight: fontWeight,
        color: color,
        textTransform: 'none',
        ...(variant === 'contained' && {
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05)',
          '&:hover': {
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            boxShadow: '0 8px 11px -3px rgb(0 0 0 / 0.05)',
            transform: 'translateY(-1px)',
          },
        }),
        ...(variant !== 'contained' && {
          '&:hover': {
            backgroundColor: backgroundColor,
            borderColor: borderColor,
          },
        }),
        '&:active': {
          backgroundColor: backgroundColor,
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
