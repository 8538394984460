import React, { useState, useEffect, useRef } from 'react';
import { languages } from '../../../../i18n/languages';
import i18next from 'i18next';
import { navigate } from '../../navigate';


const ToggleLanguage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(i18next.language); // Manage selected language state
  const dropdownRef = useRef<HTMLDivElement>(null);
  const currentPath =window.location.pathname
  // Initialize language from sessionStorage or default to i18n.language
  useEffect(() => {
    const storedLang = sessionStorage.getItem('language');
    if (storedLang && storedLang !== i18next.language) {
      i18next.changeLanguage(storedLang);
      setSelectedLang(storedLang);
    }
  }, []);
 

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const storedLang = sessionStorage.getItem('language') || i18next.language;
  
    if (currentPath.includes('/si/') && storedLang !== 'si') {
      handleLanguageChange('si', false); // Don't update history
    } else if (currentPath.includes('/ta/') && storedLang !== 'ta') {
      handleLanguageChange('ta', false); // Don't update history
    } else if (!currentPath.match(/^\/(si|ta)\//) && storedLang !== 'en') {
      handleLanguageChange('en', false); // Don't update history
    }
  }, [currentPath]);
  
  // Handle language change
  const handleLanguageChange = (lang: string, updateHistory: boolean = true) => {
    if (selectedLang === lang) return; // Prevent unnecessary re-renders
  
    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}\//, '/');
    const newPath = lang !== 'en' ? `/${lang}${pathWithoutLang}` : pathWithoutLang;
  
    i18next.changeLanguage(lang);
    sessionStorage.setItem('language', lang);
    setSelectedLang(lang);
    setIsOpen(false);
  
    // Only update history if the change is user-triggered
    if (updateHistory) {
      navigate(newPath);
    }
  };
  

  return (
    <div className="relative flex w-fit items-center px-5" ref={dropdownRef}>
      {/* Language Selector Trigger */}
      <button
        type="button"
        className="flex items-center gap-2 rounded-lg border px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-100"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
        aria-label="Select Language"
      >
        {languages[selectedLang as keyof typeof languages]?.label}{' '}
        {languages[selectedLang as keyof typeof languages]?.flag}
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute left-0 top-full z-10 mt-2 w-full rounded-md border bg-white shadow-lg">
          {Object.keys(languages).map((lang) => (
            <button
              key={lang}
              onClick={() => {
                handleLanguageChange(lang)              
              }}
              className={`flex w-full items-center gap-2 px-4 py-2 text-left text-sm hover:bg-gray-200 ${
                selectedLang === lang ? 'bg-gray-100 font-bold' : ''
              }`}
            >
              {languages[lang as keyof typeof languages].label}{' '}
              {languages[lang as keyof typeof languages].flag}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ToggleLanguage;
