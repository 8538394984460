import React from 'react';
import { useTranslation } from 'react-i18next';
import DropDown from './DropDown';
import { Link } from 'gatsby';
import i18next from 'i18next';

type ReusableLinkProps = {
  to: string;
  children: React.ReactNode;
};

export const ReusableLink: React.FC<ReusableLinkProps> = ({ to, children }) => {
  return (
    <Link
      to={`${i18next.language !== 'en' ? `/${i18next.language}` : ''}${to}`}
      activeStyle={{ color: '#109CDC' }}
      className="text-[1.45rem] font-[700] text-base-black no-underline lg:text-[1.4rem] 2xl:text-[1.5rem]"
    >
      {children}
    </Link>
  );
};

const NavLink: React.FC = () => {
  const { t } = useTranslation('navbar');
  return (
    <div className="flex w-[98%] justify-center gap-10 text-center">
      <ReusableLink to="/">{t('navigation.home')}</ReusableLink>
      <ReusableLink to="/about-us">{t('navigation.aboutUs')}</ReusableLink>
      <DropDown host="aboutDiabetes">
        <ReusableLink to="/about-diabetes">
          {t('navigation.aboutDiabetes')}
        </ReusableLink>
      </DropDown>
      <DropDown host="ourPrograms">
        <ReusableLink to="/programs">
          {t('navigation.ourPrograms')}
        </ReusableLink>
      </DropDown>
      <ReusableLink to="/library">{t('navigation.library')}</ReusableLink>
      <DropDown host="contactUs">
        <ReusableLink to="/contact-us">
          {t('navigation.contactUs')}
        </ReusableLink>
      </DropDown>
    </div>
  );
};

export default NavLink;
