interface LanguageDetails {
  label: string;
  flag: string;
  shortForm: string;
}

export const languages: Record<'en' | 'si' | 'ta', LanguageDetails> = {
  en: {
    label: 'English',
    flag: '',
    shortForm: 'En',
  },
  si: {
    label: 'සිංහල',
    flag: '',
    shortForm: 'සිං',
  },
  ta: {
    label: 'தமிழ்',
    flag: '',
    shortForm: 'த',
  },
};
