import React from 'react';

type Props = {
  url: string;
  label: React.ReactNode;
  className?: string;
  target?: string;
  rel?: string;
  ariaLabel?: string;
};

const CustomLink = ({ url, label, className, target, rel, ariaLabel }: Props) => {
  // If label is an image/icon, we need an aria-label
  const needsAriaLabel = React.isValidElement(label);
  
  return (
    <div>
      <a
        href={url}
        className={`flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline hover:text-light-blue ${className}`}
        target={target}
        rel={rel}
        aria-label={ariaLabel || (typeof label === 'string' ? label : undefined)}
      >
        {label}
      </a>
    </div>
  );
};

export default CustomLink;
