import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

// Component imports
import NavLink from './components/NavLink';
import XlLogo from '../logo/XlLogo';
import ToggleLanguage from './components/ToggleLanguage';
import CustomButton from '../buttons/CustomButton';
import NavDrawer from './components/NavDrawer';
import BookFreeProgram from '../modals/BookFreeProgram';

const NavBar: React.FC = () => {
  const { t, i18n } = useTranslation('navbar');
  const [open, setOpen] = useState(false);

  const handleClick = (): void => {
    setOpen(true);
  };

  return (
    <div className="w-full">
      {/* Desktop View */}
      <div className="fixed top-0 z-[1000] hidden w-full lg:block">
        {/* <NavBarHeader /> */}
        <div className="fixed top-[4.2rem] z-[1000] flex h-[10rem] w-full items-center border border-primary-gray bg-base-white px-[5.1rem]">
          {/* Logo */}
          <div className="flex items-center justify-center">
            <Link
              to={`${i18n.language !== 'en' ? `/${i18n.language}` : ''}/`}
              className="flex items-center"
            >
              <XlLogo width="15rem" />
            </Link>
          </div>

          {/* Language Toggle */}
          <div className="w-[10%]">
            <ToggleLanguage />
          </div>

          {/* Navigation Links */}
          <div className="flex w-[80%] justify-center">
            <NavLink />
          </div>

          {/* Custom Button */}
          <div>
            <CustomButton
              onClick={handleClick}
              width="20rem"
              height="4.6rem"
              borderColor="#FAA61A66"
              borderRadius="high"
            >
              <p className="text-sm font-[700] leading-4">
                {t('bookBtn.text1')}{' '}
                <span className="whitespace-nowrap text-sm text-light-blue">
                  {t('bookBtn.text2')}
                </span>{' '}
                {t('bookBtn.text3')}
              </p>
            </CustomButton>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="fixed top-0 z-[1000] h-[8rem] w-screen items-center justify-between lg:hidden">
        <NavDrawer />
      </div>

      {/* Modal */}
      <BookFreeProgram open={open} setOpen={setOpen} />
    </div>
  );
};

export default NavBar;
