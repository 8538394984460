import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import NavBar from '../common/navBar/NavBar';
import Footer from '../common/footer/Footer';
import NavBarHeader from '../common/navBar/components/NavBarHeader';
import { FaWhatsapp } from 'react-icons/fa';
type Props = {
  children: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const [isClient, setIsClient] = useState(false);
  const location =
    typeof window !== 'undefined' ? useLocation() : { pathname: '/' };

  // Scroll to top on location change
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsClient(true);
  }, [setIsClient]);

  if (!isClient) return null;

  return (
    <div className="relative">
      <div className="fixed top-0 z-[1000] w-screen lg:block">
        <NavBarHeader />
        <NavBar />
      </div>
      {/* Main content area with initial top margin */}
      <main className="mt-[8rem] lg:mt-[14rem]">{children}</main>
      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/94773533791"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-6 right-10 z-50 flex h-14 w-14 items-center justify-center rounded-full bg-[#25D366] text-white shadow-lg transition-transform hover:scale-110 hover:shadow-xl"
        aria-label="Chat on WhatsApp"
      >
        <FaWhatsapp className="text-3xl" />
      </a>
      <Footer />
    </div>
  );
};

export default Layout;
