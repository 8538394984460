import React, { createContext, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

interface VideoNode {
  id: string;
  title: string;
  url: string;
  date: string;
  language: string;
}

interface TikTokNode {
  id: string;
  title: string;
  url: string;
  date: string;
  language: string;
}

interface ArticleNode {
  id: string;
  title: string;
  image: Images;
  description: string;
  slug: string;
  date: string;
}

interface ResourceNode {
  id: string;
  title: string;
  image: Images;
  downloads: {
    file: {
      url: string;
    };
  };
}
interface TeamNode {
  id: string;
  image: Images;
  name: string;
  designation: string;
  bio: string;
  priority: number;
}
interface NewsNode {
  image: Images;
  description: string;
  link: string;
  title: string;
  date: string;
}
interface Images {
  images: IGatsbyImageData;
}

interface WorkshopNodes {
  category: string;
  gallery: IGatsbyImageData;
}

interface ContentfulDataContextType {
  videoNodes: VideoNode[];
  tiktokNodes: TikTokNode[];
  articleNodes: ArticleNode[];
  downloadableResources: ResourceNode[];
  teamNodes: TeamNode[];
  newsNodes: NewsNode[];
  galleryNodes: Images[];
  workshopNodes: WorkshopNodes[];
}

const ContentfulDataContext = createContext<
  ContentfulDataContextType | undefined
>(undefined);

export const ContentfulDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVideo(sort: { priority: ASC }) {
        nodes {
          id
          title
          url
          date
          language
        }
      }
      allContentfulArticle(sort: { priority: ASC }) {
        nodes {
          id
          title
          image {
            gatsbyImageData
            file {
              url
            }
          }
          description
          slug
          date
          author
          language
        }
      }
      allContentfulDownloadableResources(sort: { updatedAt: DESC }) {
        nodes {
          id
          title
          downloads {
            file {
              url
            }
          }
          image {
            gatsbyImageData
          }
          language
        }
      }
      allContentfulTeam {
        nodes {
          id
          name
          slug
          priority
          designation
          priority
          bio {
            bio
          }
          image {
            gatsbyImageData
            file {
              url
            }
          }
          language
        }
      }
      allContentfulNews(sort: { date: DESC }) {
        nodes {
          id
          title
          image {
            gatsbyImageData
            file {
              url
            }
          }
          description
          slug
          date
          language
        }
      }
      allContentfulGallery {
        nodes {
          id
          images {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      allContentfulWorkshop {
        nodes {
          category
          video
          gallery {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            file {
              url
            }
          }
        }
      }
    }
  `);

  const {
    allContentfulVideo,
    allContentfulTikTok,
    allContentfulArticle,
    allContentfulDownloadableResources,
    allContentfulTeam,
    allContentfulNews,
    allContentfulGallery,
    allContentfulWorkshop,
  } = data;

  // Extracting nodes
  const videoNodes = allContentfulVideo.nodes;
  const tiktokNodes = allContentfulTikTok?.nodes || [];
  const articleNodes = allContentfulArticle.nodes;
  const downloadableResources = allContentfulDownloadableResources.nodes;
  const teamNodes = allContentfulTeam.nodes;
  const newsNodes = allContentfulNews.nodes;
  const galleryNodes = allContentfulGallery.nodes;
  const workshopNodes = allContentfulWorkshop.nodes;

  return (
    <ContentfulDataContext.Provider
      value={{
        videoNodes,
        tiktokNodes,
        articleNodes,
        downloadableResources,
        teamNodes,
        newsNodes,
        galleryNodes,
        workshopNodes,
      }}
    >
      {children}
    </ContentfulDataContext.Provider>
  );
};

export const useContentfulData = () => {
  const context = useContext(ContentfulDataContext);
  if (context === undefined) {
    throw new Error(
      'useContentfulData must be used within a ContentfulDataProvider'
    );
  }
  return context;
};
