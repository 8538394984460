import React, { useState } from 'react';
import ModalWrapper from '../ModalWrapper';
import { Formik, Form, Field } from 'formik';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import axios from 'axios';
import CustomButton from '../buttons/CustomButton';
import { formIds } from '../../../utils/formIds';
import { FaSpinner } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { IoClose } from 'react-icons/io5';

type Prop = {
  open: boolean;
  setOpen: (e: boolean) => void;
  defaultSelect?:
    | 'School/University'
    | 'Corporate'
    | 'Community'
    | 'Support Group';
};

const BookFreeProgram = ({
  open,
  setOpen,
  defaultSelect = 'School/University',
}: Prop) => {
  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    program: defaultSelect,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    phone: Yup.string()
      .matches(/^(?:\+94|0)\d{9}$/, 'Invalid phone number format')
      .required('Phone number is required'),
    message: Yup.string(),
    program: Yup.string().required('Select is required'),
  });

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      fontSize: '1.6rem',
      '& fieldset': {
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderColor: '#2f82b4',
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.5rem',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '1.2rem',
    },
  };

  return (
    <ModalWrapper open={open} setOpen={setOpen}>
      <ToastContainer />
      <div className="absolute left-1/2 top-1/2 flex h-fit w-[90%] -translate-x-1/2 -translate-y-1/2 justify-center rounded-2xl bg-white p-6 shadow-2xl sm:w-[55%] md:w-[500px]">
        <button
          onClick={() => setOpen(false)}
          className="absolute right-5 top-5 z-20 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-600 shadow-lg transition-colors hover:bg-gray-100"
        >
          <IoClose size={20} />
        </button>
        <div className="flex w-full flex-col rounded-xl px-8 py-6">
          <StaticImage
            src="../../../images/smLogo.svg"
            alt="Suwa Diviya"
            placeholder="blurred"
            className="mx-auto mb-6 h-16 w-16"
          />
          <h1 className="font-poppins mb-2 text-center text-3xl font-bold text-primary-blue">
            {t('bookingModel.title.part1', 'Book A Free Program')}
          </h1>
          <p className="font-poppins mb-8 text-center text-lg font-medium text-light-blue">
            {t('bookingModel.title.part2', 'Register Now!')}
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                setIsSubmitting(true);
                await axios.post(
                  `${process.env.GATSBY_BACKEND_URL}/submit` || '',
                  {
                    formId: formIds.FREE_PROGRAM_FORM,
                    responses: values,
                  }
                );
                toast.success('Message sent successfully!', {
                  autoClose: 2000,
                });
                resetForm();
                setTimeout(() => {
                  setOpen(false);
                }, 3000);
              } catch {
                toast.error('Something went wrong, please try again later..');
              }
              setIsSubmitting(false);
            }}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form className="flex flex-col gap-6">
                <TextField
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  name="name"
                  label={t('bookingModel.label.name', 'Name')}
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                  sx={inputStyles}
                />
                <TextField
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  name="email"
                  label={t('bookingModel.label.email', 'Email')}
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                  sx={inputStyles}
                />
                <TextField
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  name="phone"
                  label={t('bookingModel.label.phone', 'Phone No')}
                  variant="outlined"
                  value={values.phone}
                  onChange={handleChange}
                  fullWidth
                  sx={inputStyles}
                />
                <FormControl
                  error={touched.program && Boolean(errors.program)}
                  variant="outlined"
                  fullWidth
                >
                  <InputLabel sx={{ fontSize: '1.5rem' }}>
                    {t('bookingModel.label.program.label', 'Program')}
                  </InputLabel>
                  <Select
                    name="program"
                    value={values.program}
                    onChange={handleChange}
                    label={t('bookingModel.label.program.label', 'Program')}
                    sx={{
                      fontSize: '1.6rem',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#e0e0e0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#2f82b4',
                      },
                    }}
                  >
                    <MenuItem
                      value="School/University"
                      sx={{ fontSize: '1.6rem' }}
                    >
                      {t(
                        'bookingModel.label.program.options.label1',
                        'School/University'
                      )}
                    </MenuItem>
                    <MenuItem value="Corporate" sx={{ fontSize: '1.6rem' }}>
                      {t(
                        'bookingModel.label.program.options.label2',
                        'Corporate Program'
                      )}
                    </MenuItem>
                    <MenuItem value="Community" sx={{ fontSize: '1.6rem' }}>
                      {t(
                        'bookingModel.label.program.options.label3',
                        'Community Program'
                      )}
                    </MenuItem>
                    <MenuItem value="Support Group" sx={{ fontSize: '1.6rem' }}>
                      {t(
                        'bookingModel.label.program.options.label4',
                        'Support Group'
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                  name="message"
                  label={t('bookingModel.label.message', 'Message (optional)')}
                  multiline
                  rows={4}
                  value={values.message}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  sx={inputStyles}
                />
                <CustomButton
                  type="submit"
                  height="4.5rem"
                  disabled={isSubmitting}
                  backgroundColor="#FAA61A"
                  color="white"
                  className="mt-4 rounded-lg transition-colors duration-300 hover:bg-[#fb9d01]"
                >
                  <p className="flex items-center justify-center text-lg font-semibold">
                    {isSubmitting ? (
                      <FaSpinner className="animate-spin" />
                    ) : (
                      t('bookingModel.button', 'Register')
                    )}
                  </p>
                </CustomButton>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default BookFreeProgram;
