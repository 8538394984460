import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import CustomButton from './buttons/CustomButton';
import BookFreeProgram from './modals/BookFreeProgram';

const OneStep = () => {
  const { t } = useTranslation('footer');
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row">
        {/* Left Section with Image */}
        <div className="basis-1/2">
          <StaticImage
            placeholder="blurred"
            src="../../images/team.png"
            alt="teamImage"
            layout="fullWidth"
            className="h-full"
            objectFit="cover"
            objectPosition="top"
          />
        </div>

        {/* Right Section with Overlay */}
        <div className="relative flex basis-1/2 flex-col items-center justify-center px-10 py-40 lg:py-20">
          {/* Background Image Absolutely Positioned */}
          <div className="absolute h-full w-full">
            <StaticImage
              placeholder="blurred"
              src="../../images/team(1).png"
              alt="team-bg"
              className="h-full w-full"
              objectFit="cover"
            />
          </div>

          {/* Content */}
          <div className="z-10 flex h-full w-full flex-col items-center justify-center text-center text-white lg:items-start lg:px-10 lg:text-start">
            <p className="font-poppins text-left text-lg font-medium leading-[2.06rem] underline underline-offset-2">
              {t('footer.oneStep.subtitle', 'Diabetes Awareness & Education')}
            </p>
            <h1 className="font-poppins mb-[2rem] mt-[3rem] max-w-3xl text-4xl font-semibold uppercase">
              {t(
                'footer.oneStep.title',
                'Transforming Lives, One Step at a Time'
              )}
            </h1>
            <p className="mb-[4rem] max-w-5xl text-center text-sm font-normal lg:text-start">
              {t(
                'footer.oneStep.description',
                'Suwa Diviya is dedicated to combating diabetes through a comprehensive approach that focuses on prevention, early diagnosis, and adequate control to prevent complications.'
              )}
            </p>
            <CustomButton
              width="30.7rem"
              height="4.3rem"
              borderRadius="low"
              backgroundColor="#FAA61A"
              onClick={handleClick}
            >
              <p className="font-poppins text-center text-sm font-semibold leading-[2.1rem] text-base-white">
                {t('footer.oneStep.buttonText', 'Book A Free Program')}
              </p>
            </CustomButton>
          </div>
        </div>

        {/* Modal */}
        <BookFreeProgram open={open} setOpen={setOpen} />
      </div>
    </>
  );
};

export default OneStep;
