import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../navigate';

type Props = {
  children: React.ReactNode;
  host: 'aboutDiabetes' | 'ourPrograms' | 'contactUs';
};

const DropDown: React.FC<Props> = ({ children, host }) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="relative flex w-fit items-center"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {/* Trigger */}
      <div className="select-trigger">{children}</div>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="select-options">
          {host === 'aboutDiabetes' ? (
            <>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/bmi`);
                }}
              >
                {t('dropDown.bmi', 'BMI CALCULATOR')}
              </div>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/bmr`);
                }}
              >
                {t('dropDown.bmr', 'BMR CALCULATOR')}
              </div>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/drf`);
                }}
              >
                {t('dropDown.drf', 'DIABETES RISK ASSESSMENT')}
              </div>
            </>
          ) : host === 'ourPrograms' ? (
            <>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/school`);
                }}
              >
                {t('dropDown.school', 'School/University')}
              </div>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/corporate`);
                }}
              >
                {t('dropDown.corporate', 'Corporate')}
              </div>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/community`);
                }}
              >
                {t('dropDown.community', 'community')}
              </div>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/support-group`);
                }}
              >
                {t('dropDown.supportGroup', 'Support Group')}
              </div>
              <div
                className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
                onClick={() => {
                  setIsOpen(false);
                  navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/events`);
                }}
              >
                {t('dropDown.publicEvents', 'Public Events')}
              </div>
            </>
          ) : (
            // Contact Us dropdown
            <div
              className="cursor-pointer whitespace-nowrap bg-base-white p-2 text-start text-[1.2rem] font-semibold text-base-black no-underline hover:bg-[#109CDC] hover:text-base-white lg:text-[1.15rem] 2xl:text-[1.3rem]"
              onClick={() => {
                setIsOpen(false);
                navigate(`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/volunteer`);
              }}
            >
              {t('dropDown.volunteer', 'Volunteer')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropDown;
