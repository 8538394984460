import React, { useEffect } from 'react';
import BottomBar from './components/BottomBar';
import ContentBar from './components/ContentBar';
import i18next from 'i18next';
import OneStep from '../OneStep';

const Footer = ({ oneStep = true }) => {
  useEffect(() => {
    const storedLang = sessionStorage.getItem('language');
    if (storedLang) {
      i18next.changeLanguage(storedLang);
    }
  }, []);
  return (
    <>
      {oneStep && <OneStep />}
      <div className="inset-x-0 top-0 h-8">
        {/* Orange Divider */}
        <div className="h-[0.3rem] w-full bg-warning-orange opacity-10"></div>

        <div className="px-[6rem] pt-[4rem]">
          <ContentBar />
          <div className="h-[0.1rem] w-full bg-warning-orange"></div>
          <BottomBar />
        </div>
      </div>
    </>
  );
};

export default Footer;
