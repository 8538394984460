// Import global styles
import './src/styles/global.css';
import './src/styles/main.scss';

// Import third-party styles
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';


// React and providers
import React, { useEffect } from 'react';
import { SectionProvider } from './src/store/SectionContext';
import { ContentfulDataProvider } from './src/store/ContentfulDataContext';
import { LocationProvider } from '@reach/router';
import { I18nextProvider, useTranslation } from 'react-i18next';

// Layout and i18n configuration
import Layout from './src/components/layout/Layout';
import i18nConfig from './src/i18n/i18n';
import { switchFont } from './src/utils/fontSwitcher';
import Snowfall from 'react-snowfall';

// Component to handle font switching
const FontSwitcherWrapper = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Apply the font based on the current language
    switchFont();
  }, [i18n.language]); // Run effect whenever the language changes

  return <>{children}</>;
};
// Root element wrapper
export const wrapRootElement = ({ element }) => {
  const currentMonth = new Date().getMonth(); // 0 = January, 11 = December
  return (
    <I18nextProvider i18n={i18nConfig}>
      <ContentfulDataProvider>
        <SectionProvider>
          <LocationProvider>
            <FontSwitcherWrapper>
              {/* Render Snowfall only in December */}
              <Layout>
                {currentMonth === 11 && (
                  <Snowfall
                    color="white"
                    snowflakeCount={60}
                    style={{
                      zIndex: 9998,
                      position: 'fixed',
                      width: '100vw',
                      height: '100vh',
                    }}
                  />
                )}
                {element}
              </Layout>
            </FontSwitcherWrapper>
          </LocationProvider>
        </SectionProvider>
      </ContentfulDataProvider>
    </I18nextProvider>
  );
};
