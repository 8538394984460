import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { ReusableLink } from '../components/NavLink';
import { Link } from 'gatsby';
import XlLogo from '../../logo/XlLogo';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import SmLogo from '../../logo/SmLogo';
import CustomLink from '../../CustomLink';
import { FaPhone } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ToggleLanguage from './ToggleLanguage';
import FollowUs from '../../../pages/home/components/FollowUs';

type Anchor = 'right';

export default function NavDrawer() {
  const { t } = useTranslation('navbar');

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        font: '50rem',
        width: '60dvw',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <List>
          <div className="flex justify-between px-5">
            <SmLogo width="10rem" />
            <Button onClick={toggleDrawer('right', false)} className="flex">
              {/* Close Icon */}
              <AiOutlineMenuUnfold
                fontSize={'3rem'}
                className="ml-auto text-primary-blue"
              />
            </Button>
          </div>

          <div className="mx-auto flex w-[54%] flex-col justify-start gap-10 py-10 text-start">
            <ReusableLink to="/">{t('navigation.home')}</ReusableLink>
            <ReusableLink to="/about-us">
              {t('navigation.aboutUs')}
            </ReusableLink>

            <ReusableLink to="/about-diabetes">
              {t('navigation.aboutDiabetes')}
            </ReusableLink>

            <ReusableLink to="/programs">
              {t('navigation.ourPrograms')}
            </ReusableLink>
            <ReusableLink to="/library">{t('navigation.library')}</ReusableLink>
            <ReusableLink to="/contact-us">
              {t('navigation.contactUs')}
            </ReusableLink>
          </div>
        </List>
      </div>
      <div className="mt-auto">
        <FollowUs variant="drawer" />
      </div>
      <div className="">
        <div className="mt-[3rem] rounded-md bg-[#0196D9] px-[2rem]">
          <div className="flex flex-col">
            <div className="flex items-center gap-2 pt-5 text-warning-orange">
              <IoLocationSharp style={{ fontSize: '1.2rem' }} />
              <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
                {t(
                  'header.address',
                  '139 Srimath Anagarika Dharmapala Mawatha, Colombo 7'
                )}
              </div>
            </div>
            <div className="flex items-center gap-2 py-5 text-warning-orange">
              <FaPhone style={{ fontSize: '1.2rem' }} />
              <CustomLink url="tel:+94773533791" label="+94 77 353 3791" />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );

  return (
    <div className="flex h-[8rem] w-screen items-center justify-between bg-base-white px-5 py-2">
      {/* Logo */}
      <Link to={`${i18next.language !== 'en' ? `/${i18next.language}` : ''}/`}>
        <XlLogo width="13rem" />
      </Link>

      <div className="flex gap-1">
        {/* Language Toggle */}
        {/* <MobileToggleLng /> */}
        <ToggleLanguage />

        {/* Drawer Button */}
        <Button
          onClick={toggleDrawer('right', true)}
          sx={{
            margin: 0,
            padding: 0,
            display: 'flex',
          }}
        >
          <AiOutlineMenuFold className="text-3xl text-primary-blue" />
        </Button>
      </div>

      {/* Drawer */}
      <SwipeableDrawer
        anchor="right"
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {list('right')}
      </SwipeableDrawer>
    </div>
  );
}
