import React from 'react';
import CustomLink from '../../CustomLink';
import Divider from './Divider';
import { BiLogoFacebook } from 'react-icons/bi';
import { FiInstagram } from 'react-icons/fi';
import { FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { FaPhone } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby-link';

const NavBarHeader = () => {
  const { t } = useTranslation('navbar');

  return (
    <div className="flex h-[4.2rem] items-center justify-between bg-primary-blue px-[5.1rem]">
      <div className="flex h-5 items-center gap-2">
        <Link
          to="/programs"
          className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline"
        >
          {t('header.note01', 'FREE Programs for Schools & Corporations')}
        </Link>
        <Divider />
        <Link
          to="/library"
          className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline"
        >
          {t('header.note02', 'Diabetes Downloads & Resources')}
        </Link>
        <Divider />
        <a
          href="https://chat.whatsapp.com/E8iUYhiofmfFRa3i7HUCdn"
          target="_blank"
          rel="noreferrer"
          className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline"
        >
          {t('header.note03', 'Join The Support Group')}
        </a>
      </div>
      <div className="flex gap-2">
        <div className="flex items-center gap-2 text-warning-orange">
          <IoLocationSharp style={{ fontSize: '1.2rem' }} />
          <div className="flex items-center text-[1.1rem] font-[500] leading-[1.3rem] text-base-white no-underline">
            {t('header.address', '139 Srimath Anagarika Dharmapala Mawatha')}
          </div>
        </div>
        <Divider />
        <div className="flex items-center gap-2 text-warning-orange">
          <FaPhone style={{ fontSize: '1.2rem' }} />
          <CustomLink url="tel:+94773533791" label="+94 77 353 3791" />
        </div>
        <Divider />
        <div className="flex gap-4 text-white">
          <Link
            to="https://web.facebook.com/suwadiviyalk/?_rdc=1&_rdr"
            target="_blank"
            rel="noreferrer"
          >
            <BiLogoFacebook
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
          <Link
            to="https://www.linkedin.com/company/suwa-diviya/posts/?feedView=all"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn
              style={{ fontSize: '1.1rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
          <Link
            to="https://www.instagram.com/suwa_diviya/"
            target="_blank"
            rel="noreferrer"
          >
            <FiInstagram
              style={{ fontSize: '1.1rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
          <Link
            to="https://www.youtube.com/@SuwaDiviya"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube
              style={{ fontSize: '1.2rem', color: 'white' }}
              className="cursor-pointer"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBarHeader;
